import type { MetaInfo } from 'vue-meta';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
  };
  seoTags.meta.push({
    name: 'robots',
    content: 'noindex, nofollow',
  });
  
  seoTags.meta.push({
    hid: 'apple-mobile-web-app-title',
    name: 'apple-mobile-web-app-title',
    content: process.env.VSF_DEFAULT_WEBSITE_META_TITLE || 'Venu\'s Digital Arcade',
  });

  seoTags.meta.push({
    hid: 'og:site_name',
    name: 'og:site_name',
    content: process.env.VSF_DEFAULT_WEBSITE_META_TITLE || 'Venu\'s Digital Arcade',
  });

  seoTags.meta.push({
    hid: 'og:url',
    name: 'og:url',
    content: process.env.VSF_STORE_URL || 'https://venusdigitalarcade.com/'
  });

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.meta_title || page?.title || page?.name;

    seoTags.meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: page?.meta_title || page?.title || page?.name,
    });
  }

  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description || 'Venu\'s Digital Arcade',
    });
    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: page.meta_description || 'Venu\'s Digital Arcade',
    });
  }

  if (page?.meta_keyword || page?.meta_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords,
    });
  }

  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }
  return seoTags;
};
